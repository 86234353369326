'use client';

import dynamic from 'next/dynamic';
import { Suspense } from 'react';

const DynamicFooter = dynamic(() => import('@fe-common-ui/Footer'), {
  ssr: false
});

export default function DynamicLazyLoadFooter() {
  return (
    <Suspense>
      <DynamicFooter />
    </Suspense>
  );
};
