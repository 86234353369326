import { GETv1UserTagIsTracked } from '@fe-news/api/member';
import { Response } from '@fe-news/constants/api';

export const fetchUserTagIsTracked = async (isLogin: boolean): Promise<Response<string[]>> => {
  try {
    if (!isLogin) {
      return { isError: false, data: [] as string[] };
    }
    const data = await GETv1UserTagIsTracked();
    return { data, isError: false };
  } catch (error) {
    console.error('[API Error]: fetchUserTagIsTracked', error);
    return { isError: true, message: `[API Error]: fetchUserTagIsTracked error:${error}` };
  }
};
