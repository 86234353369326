'use client';

import PopAdProvider from '@fe-news/context/pop-ad-provider';
import useMediaQuery from '@fe-news/hooks/use-media-query';
import dynamic from 'next/dynamic';
import { Suspense } from 'react';

const DynamicCoverDeskTopModal = dynamic(
  () => import('@fe-news/components/NewsAdCover/CoverDeskTopModal/CoverDeskTopModal'),
  { ssr: true }
);

const DynamicNewsAdCoverDesktop = dynamic(
  () => import('@fe-news/components/molecules/NewsAdCover/NewsAdCoverDesktop'),
  { ssr: true }
);

const DynamicNewsAdCoverMobile = dynamic(() => import('@fe-news/components/molecules/NewsAdCover/NewsAdCoverMobile'), {
  ssr: true
});

const DynamicNewsAdCoverDetailMobile = dynamic(
  () => import('@fe-news/components/molecules/NewsAdCoverDetail/NewsAdCoverDetailMobile'),
  { ssr: true }
);

const NewsPopAd = () => {
  const isDesktop = useMediaQuery('(min-width: 768px)');

  if (isDesktop) {
    return (
      <Suspense>
        {/* 桌機全站蓋版廣告 */}
        <DynamicNewsAdCoverDesktop />
        {/* 桌機閒置彈跳視窗廣告 */}
        <DynamicCoverDeskTopModal />
      </Suspense>
    );
  }

  return (
    <PopAdProvider>
      <Suspense>
        {/* 手機全站蓋版廣告 */}
        <DynamicNewsAdCoverMobile />
        {/* 手機插頁廣告 */}
        <DynamicNewsAdCoverDetailMobile />
      </Suspense>
    </PopAdProvider>
  );
};

export default NewsPopAd;
