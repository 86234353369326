'use client';

import { createContext, useMemo, useState } from 'react';

type CategoryContextValue = {
  category: MaybeNullable<string>;
  setCategory?: React.Dispatch<React.SetStateAction<MaybeNullable<string>>>;
};

export const CategoryContext = createContext<CategoryContextValue>({
  category: null
});

export type CategoryProviderPros = {
  category?: MaybeNullable<string>;
  children: React.ReactNode;
};

export default function CategoryProvider({ category = '', children }: CategoryProviderPros) {
  const [currentCategory, setCurrentCategory] = useState<MaybeNullable<string>>(category);
  const value = useMemo(
    () => ({
      category: currentCategory,
      setCategory: setCurrentCategory
    }),
    [currentCategory]
  );

  return <CategoryContext.Provider value={value}>{children}</CategoryContext.Provider>;
}

// 教學文:
// https://medium.com/comsystoreply/how-to-use-react-context-with-usestate-c8ae4fe72fb9
