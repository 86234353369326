'use client';

import { generateSearchMoreLink } from '@fe-common-utils/libs/link';
import { styled } from '@linaria/react';
import type { ReactNode } from 'react';

const View = styled.div`
  display: block;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 22px;
  padding: 0 8px;
  background-color: #edf1f8;
  border: none;

  > p {
    color: #515c69;
    font-size: 10px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.5px;
  }

  > a {
    font-size: 10px;
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: 0.5px;
    text-align: right;
    color: #848d97;

    &:hover {
      color: #515c69;
    }
  }

  > button {
    color: #848d97;
    font-size: 10px;
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: 0.5px;
    cursor: pointer;

    &:hover {
      color: #515c69;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  p, span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`;

type AnueSearchResultContentWrapperProps = {
  title: string;
  more?: string;
  keyword?: string;
  isLoaded: boolean;
  children: ReactNode;
  headerActionName?: string;
  onHeaderActionClick?: () => void;
};

const AnueSearchResultWrapper = ({
  title,
  more = '',
  keyword = '',
  isLoaded = true,
  children,
  headerActionName = '',
  onHeaderActionClick,
}: AnueSearchResultContentWrapperProps) => {
  const moreLink = generateSearchMoreLink({ title, keyword });

  const renderHeader = () => (
    <Header>
      {<p>{title}</p>}
      {more && <a href={moreLink} target='_self'>{more}</a>}
      {headerActionName && <button onClick={onHeaderActionClick}>{headerActionName}</button>}
    </Header>
  );

  return isLoaded ? (
    <View>
      {title && renderHeader()}
      <Container>
        {children}
      </Container>
    </View>
  ) : null;
};

export default AnueSearchResultWrapper;
