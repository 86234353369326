import(/* webpackMode: "eager" */ "/opt/app/apps/fe-news/app/(main)/layout.linaria.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/molecules/AnueHeader/AnueHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/templates/DynamicLoad/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/templates/DynamicLoad/GlobalShare.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/components/templates/DynamicLoad/PopAd.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/context/category-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/apps/fe-news/context/query-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AD_EMPTY_EVENT","AD_RENDERED_EVENT","default"] */ "/opt/app/libs/fe-dfp/src/lib/adslot/adslot.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/app/libs/fe-dfp/src/lib/dynamicAdslot/dynamicAdslot.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Initialize"] */ "/opt/app/libs/fe-dfp/src/lib/initialize/initialize.tsx");
;
import(/* webpackMode: "eager" */ "/opt/app/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_react-dom@18.3.0_react@18.3.1__react@18.3.1_sass@1.77.6/node_modules/next/dist/client/script.js");
