/**
 * 在 Next.js 中使用 DOMPurify 時，會發生
 * `Next.js DOMPurify.sanitize() shows TypeError: dompurify__WEBPACK_IMPORTED_MODULE_6___default.a.sanitize is not a function` 的錯誤
 * 原因是無在 Server 端執行，因此需要使用 `isomorphic-dompurify` 來解決，這套件可以在 Client 端與 Server 端執行。
 *
 * 參考: https://stackoverflow.com/questions/65646007/next-js-dompurify-sanitize-shows-typeerror-dompurify-webpack-imported-module
 *  */
import DOMPurify from 'isomorphic-dompurify';

export default function sanitize(value = ''): string {
  return decodeURIComponent(DOMPurify.sanitize(value));
}
