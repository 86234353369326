'use client';

import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { styled } from '@linaria/react';
import { CircleSkeleton, RectangleSkeleton } from '../Skeleton';

const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 114px;

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    margin-left: 0;
  }
`;

export default function AnueAccountSkeleton() {
  return (
    <Box>
      <CircleSkeleton width={26} height={26} style={{ marginRight: '6px' }} />
      <RectangleSkeleton width={84} height={24} />
    </Box>
  );
}
