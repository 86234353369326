'use client';

import { styled } from '@linaria/react';
import { memo } from 'react';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  color: #909090;
`;

type AnueSearchRealtimeEmptyProps = {
  children?: React.ReactNode;
};

const AnueSearchResultEmpty = ({ children }: AnueSearchRealtimeEmptyProps) => {
  return (
    <Container>{children}</Container>
  )
};

export default memo(AnueSearchResultEmpty);
