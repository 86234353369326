import { apiClient, essApiClient, wsApiClient } from './clients';

/**
 * Header AnueSearch 熱搜標籤
 * @returns Promise
 */
export const GETRecommendTags = () => {
  const url = '/api/v1/news/recommend/tags';

  return apiClient.get(url);
};

/**
 * Header AnueSearch 熱搜
 * @returns Promise
 */
export const GETHotQuote = () => {
  const url = '/ws/api/v1/ga/pageViewHot';

  return wsApiClient.get(url);
};

/**
 * Header全站搜尋
 * @param {string} word
 * @param {number} limit
 * @returns Promise
 */
export const GETSearchKeyword = (word: string, limit: number = 5) => {
  const category = 'TW,FUND,US,CC,INDEX,FUTURE,ETF,FX,EOD,CHK';
  const params = { limit, q: word, category };
  const url = '/ess/api/v1/siteSearch/main';

  return essApiClient.get(url, params);
};
