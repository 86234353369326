'use client';
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
/**
 * 小紅點出現時機：每天第一次"登入"時出現，點擊小鈴噹後消失，暫時不處理已讀未讀
 */

import { styled } from '@linaria/react';
import { memo, useEffect, useState } from 'react';
import Cookie from 'universal-cookie';

import Icon from './Icon';

type BellProps = {
  href?: string;
  style?: React.CSSProperties;
  width?: number;
  height?: number;
  animationSpeed?: number;
  color?: string;
  animate?: boolean;
  hover?: boolean;
  enableBadge?: boolean;
  isLogin?: boolean;
};

const DEFAULT_COOKIE_NAME = 'first-load-badge';
let cookie: any;

const DEFAULT_PROPS = {
  width: 24,
  height: 24,
  animationSpeed: 0.3,
  color: '#383838',
  animate: false,
  hover: false,
  href: '/',
  enableBadge: true,
  isLogin: false,
  style: {},
};

const Container = styled.a`
  cursor: pointer;
  font-size: 20px;
  position: relative;
  height: 24px;
`;

const Badge = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #e03f19;
`;

function getExpiredTime() {
  const date = new Date();

  date.setHours(0, 0, 0, 0);

  return date;
}

function getWillExpireTime() {
  const date = new Date();

  date.setHours(23, 59, 59, 59);

  return date;
}

const BellIcon = (props: BellProps = DEFAULT_PROPS) => {
  const { href, style, ...rest } = props;
  const [isShowBadge, setIsShowBadge] = useState<boolean>(false);
  const [firstLoadBadgeTime, setFirstLoadBadgeTime] = useState(null);
  // 因為是每天第一次登入，以午夜 00 時做為前一天的臨界點
  const expiredTime = getExpiredTime().getTime();

  /**
   * 這邊的思維是，當用戶點擊小鈴噹時，會將點擊時間記到 cookie，但由於是每天第一次登入
   * 所以過期時就是以午夜 00 為一個界線
   * 前一天的點擊時間一定是小於今天午夜 00 時，今天的點擊時間一定是超過午夜 00 時
   *
   *  @returns {boolean} true | false
   */
  const checkAndShowBadge = () => {
    const isExpired = firstLoadBadgeTime
      ? firstLoadBadgeTime >= expiredTime
      : false;

    setIsShowBadge(!isExpired);
  };

  useEffect(() => {
    cookie = new Cookie();
    setFirstLoadBadgeTime(cookie.get(DEFAULT_COOKIE_NAME) || null);

    checkAndShowBadge();
  }, []);

  /**
   * ANUE-6622
   * 暫不處理已讀未讀情境，但因為要讓用戶清除小紅點，所以當使用者點擊過小鈴鐺時,
   * 利用 cookie 記錄該行為，用來清除之後顯示的小紅點
   */
  const handleClickBell = () => {
    if (firstLoadBadgeTime) return;

    const domain = window.location.hostname
      .split('.')
      .filter((_, i) => i !== 0)
      .join('.');

    cookie.set(DEFAULT_COOKIE_NAME, Date.now(), {
      path: '/',
      domain: `.${domain}`,
      expires: getWillExpireTime(),
    });

    setIsShowBadge(false);
  };

  return (
    <Container
      href={href}
      target="_self"
      onClick={handleClickBell}
      style={style}
    >
      <Icon {...rest} />
      {isShowBadge && <Badge />}
    </Container>
  );
};

export default memo(BellIcon);
