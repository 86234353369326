/* eslint-disable @typescript-eslint/no-explicit-any */
import { generateFundLink, generateStockLinkBySymbol } from '@fe-common-utils/libs/link';

export const getCategory = (item: any) => {
  const { mtype, type, market, objectType } = item;

  if (objectType === 'FUND') {
    return '基金';
  }

  switch (mtype) {
    case 'STOCK': {
      if (type === 'COMMON') {
        switch (market) {
          case 'TWS':
          case 'TWG':
            return '台股';
          case 'USS':
            return '美股';
          case 'HKS':
          case 'CNS':
            return '陸港股';
          default:
            return '';
        }
      } else {
        return 'ETF';
      }
    }
    case 'FOREX': {
      if (market === 'CC') {
        return '加密貨幣';
      }

      return '外匯';
    }
    case 'FUTURES':
      return '期貨';
    case 'INDEX':
      return '指數';
    case 'EOD':
      return 'EOD';
    // eslint-disable-next-line no-fallthrough
    default:
      return '';
  }
};

export const getExchange = (item: any) => {
  const { mtype, exchange, market, objectType } = item;

  if (objectType === 'FUND') {
    return '';
  }

  switch (mtype) {
    case 'STOCK':
    case 'FUTURES':
      return exchange;
    case 'FOREX':
      if (market === 'CC') {
        return exchange;
      }

      return '';
    case 'INDEX':
    case 'EOD':
    default:
      return '';
  }
};

export const generateTradeSearchLink = (item: any, type: string) => {
  let link;

  switch (type) {
    case 'FUND':
      link = generateFundLink(item.displayNameLocal || item.displayName, item.cnyesId);
      break;
    case 'QUOTE':
    default:
      link = generateStockLinkBySymbol(item.symbol, item.type);
  }

  return link;
};
