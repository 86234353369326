'use client';

import { Dispatch, createContext, useMemo, useState } from 'react';

type PopAdContextValue = {
  isHideModalAd?: boolean;
  setIsHideModalAd?: Dispatch<React.SetStateAction<boolean>>;
};

export const PopAdContext = createContext<PopAdContextValue>({
  isHideModalAd: false
});

export type PopAdProviderPros = {
  children?: React.ReactNode;
  isHideModalAd?: boolean;
  setIsHideModalAd?: Dispatch<React.SetStateAction<boolean>>;
};

/**
 * 負責管理全站蓋版廣告與插頁廣告二者顯示
 *
 * 2024/02/29:
 * 與廣告部 (Tammy, Amy)討論後，得到的結論
 * 目前全站廣告的顯示頻次不高，但插頁廣告有業主希望達成的業績目標，顯示頻次較高
 * 所以當二者同時出現時，擇一出現，以插頁廣告為最優先
 */
const PopAdProvider = ({ children }: PopAdProviderPros) => {
  const [isHideModalAd, setIsHideModalAd] = useState(false);

  const contextValue = useMemo(
    () => ({
      isHideModalAd,
      setIsHideModalAd
    }),
    [isHideModalAd]
  );

  return <PopAdContext.Provider value={contextValue}>{children}</PopAdContext.Provider>;
};

export default PopAdProvider;
