'use client';

import accountNavigatorConfig from '@fe-news/configs/account-navigator.json';
import desktopHeaderConfig from '@fe-news/configs/desktop-header.json';
import AnueAccountSkeleton from '@fe-common-ui/AnueAccount/AnueAccountSkeleton';
import AnueNavigator from '@fe-common-ui/AnueNavigator';
import HeaderSubTabs from '@fe-common-ui/HeaderSubTabs';
import { RectangleSkeleton } from '@fe-common-ui/Skeleton/Skeleton';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { getBaseUrlEnv } from '@fe-common-utils/libs/environment';
import LinkFactory from '@fe-common-utils/libs/link';
import useAuth, { getProfile, init, login, logout } from '@fe-news/hooks/auth';
import { useCategoryContext } from '@fe-news/hooks/use-category-context';
import { getCategoryFromPathname, mainRootForSubHeaderUse } from '@fe-news/utils/category';
import { styled } from '@linaria/react';
import cx from 'classnames';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import { FC, Suspense, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchUserTagIsTracked } from '@fe-news/actions/member';
import useFollowStocks from '@fe-news/components/IndexMobileWrapper/FollowStocks/hooks/use-follow-stocks';
import { GETv1Portfolios } from '@fe-news/api/member';
import { MyCollectionProductType } from '@fe-news/constants/my-track/type';
import useMyCollection from '@fe-news/hooks/use-my-collection';

const DynamicBellIcon = dynamic(() => import('@fe-common-ui/Icons/Bell/BellIcon'), {
  loading: () => <RectangleSkeleton width={24} height={24} />,
  ssr: false
});

const DynamicAccountPanel = dynamic(() => import('@fe-common-ui/AccountPanel'), {
  ssr: false
});

const DynamicAnueAccount = dynamic(() => import('@fe-common-ui/AnueAccount'), {
  loading: () => <AnueAccountSkeleton />,
  ssr: false
});

type Props = {
  channel: string;
  isHideLoginForIe: boolean;
  keyword: string;
  isShowShadowPlaceholder?: boolean;
};

const { wwwChannelLink, loginLink } = LinkFactory;

const DesktopHeaderContainer = styled.div`
  .desktop-header {
    width: 100%;
    min-width: 1200px;
    height: 50px;
    background-color: #f6f8fc;

    &.have-shadow {
      height: 55px;
    }

    @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
      display: none;
    }
  }
`;

const accountMenus = accountNavigatorConfig.items;

const NavigatorDesktop: FC<Props> = ({
  keyword,
  channel,
  isHideLoginForIe = false,
  isShowShadowPlaceholder
}): JSX.Element => {
  const [hideLogin, setHideLogin] = useState<boolean>(false);
  const [isAccountPanelOpen, setIsAccountPanelOpen] = useState<boolean>(false);

  const [status] = useAuth();
  const pathname = usePathname();
  const { category, setCategory } = useCategoryContext();

  const cnyesBaseUrl = process.env.NEXT_PUBLIC_CNYES_BASE_URL || '';
  const apiEnv = getBaseUrlEnv();

  const isLogin: boolean = status === 'login';
  // 取得使用者個人資料
  const profile: UserProfile = (isLogin && getProfile()) || {
    name: '登入',
    avatar: ''
  };

  // 取得使用者追蹤的標籤
  useQuery({
    queryKey: ['userTagIsTracked', `isLogin:${isLogin}`, `isUserNoTracked:false`],
    queryFn: () => fetchUserTagIsTracked(isLogin),
    enabled: isLogin
  });

  // 取得使用者自選股新聞

  useFollowStocks(null, isLogin);

  // 取得使用者追蹤的時事

  useMyCollection({
    productType: MyCollectionProductType.POPTOPIC,
    isLogin: isLogin
  });

  // 取得使用者收藏的新聞

  useMyCollection({
    productType: MyCollectionProductType.NEWS,
    isLogin: isLogin
  });

  useEffect(() => {
    const currentCategory = getCategoryFromPathname(pathname);

    if (currentCategory !== category && setCategory) {
      setCategory(currentCategory);
    }
  }, [category, pathname, setCategory]);

  /**
   * 用來載入會員目前登入資訊
   */
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (isHideLoginForIe) {
      setHideLogin(true);
    }
  }, [isHideLoginForIe]);

  const onRegisterHandler = () => {
    if (!isLogin) {
      window.location.href = `${loginLink}/#register`;
    }
  };

  const panelLoginHandler = () => {
    if (!isLogin) {
      login();
    }
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const panelLogoutHandler = () => {
    logout();
  };

  const onShowOrHideAccountPanel = (enable: boolean) => {
    return () => {
      setIsAccountPanelOpen(enable);
    };
  };

  return (
    <DesktopHeaderContainer>
      <div
        className={cx('desktop-header', {
          'have-shadow': isShowShadowPlaceholder
        })}
      >
        <AnueNavigator
          channelName={channel}
          logoUrl={wwwChannelLink}
          navs={desktopHeaderConfig}
          searchKeyword={keyword}
          apiEnv={apiEnv}
          Bell={
            <Suspense>
              <DynamicBellIcon isLogin={isLogin} animate href={`https://www.${cnyesBaseUrl}/events`} />
            </Suspense>
          }
          SubNavs={<HeaderSubTabs data={mainRootForSubHeaderUse} value={category || ''} />}
          Account={
            !hideLogin && (
              <Suspense>
                <DynamicAnueAccount
                  onLogin={onShowOrHideAccountPanel(true)}
                  onRegister={onRegisterHandler}
                  isLogin={isLogin}
                  userName={profile.nickname}
                  avatarSrc={profile.avatar}
                  onClick={onShowOrHideAccountPanel(true)}
                />
              </Suspense>
            )
          }
        />
      </div>
      {isAccountPanelOpen && (
        <Suspense>
          <DynamicAccountPanel
            isOpen={isAccountPanelOpen}
            isLogin={isLogin}
            login={panelLoginHandler}
            logout={panelLogoutHandler}
            profile={profile}
            onClosePanel={onShowOrHideAccountPanel(false)}
            cnyesBaseUrl={cnyesBaseUrl}
            navs={accountMenus}
          />
        </Suspense>
      )}
    </DesktopHeaderContainer>
  );
};

export default NavigatorDesktop;
