'use client';

import sanitize from '@fe-common-utils/libs/sanitize';
import { styled } from '@linaria/react';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { AnueSearchInputProps } from './AnueSearchInput.d';

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  z-index: 5;
`;

const Input = styled.input`
  position: relative;
  width: 100%;
  padding: 8px 32px 8px 7px;
  background: #f6f8fc;
  border-radius: 6px;
  font-size: 15px;
  color: #848d97;
  letter-spacing: 0.54px;

  &.desktop {
    border: 1px solid #e2e8f1;
  }

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  &::-webkit-clear-button,
  &::-webkit-inner-spin-button {
    display: none;
  }

  &:focus {
    outline: none;
    border: 1px solid #3ec1ce;
    box-shadow: 0 0 2px 2px rgb(193, 246, 255);
  }
`;

const Button = styled.div`
  position: absolute;
  right: 10px;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Icon = styled.img`
  cursor: pointer;

  .search {
    &.desktop {
      position: absolute;
      right: 0;
      top: 8px;
      z-index: 5;
    }
  }

  .close {
    position: absolute;
    right: 0;
    top: 8px;
    width: 16px;
    height: 16px;
  }
`;

const ENTER_KEY_CODE = 13;

type Ref = {
  focus: () => void;
} | null;

/**
 * 搜尋輸入框
 * 採用非受控元件模式
 *
 * 官方說明: https://zh-hans.react.dev/reference/react-dom/components/input
 * 踩坑实录 - react中input属性value和defaultValue的使用: https://juejin.cn/post/7165804525431832612
 */
const AnueSearchInput = forwardRef<Ref, AnueSearchInputProps>(({
  placeholder = '搜尋新聞、代碼或名稱',
  value,
  onFocus,
  onChange,
  onBlur,
  onEnter,
  isShowClearButton = false,
  isShowSearchButton = true,
  theme = 'desktop',
}, ref) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }));

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = value || '';
    }
  }, [value]);

  // 解決使用輸入法在 React Input 輸入框中輸入的問題
  // https://juejin.cn/post/6861098174723915790
  let compositionStatus = 'end';

  const onFocusHandler = (): void => {
    onFocus?.(true);
  };

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (event.relatedTarget) return;

    (event.target as HTMLInputElement).placeholder = sanitize(placeholder);
    onFocus?.(false);
    onBlur?.();
  };

  // 清除關鍵字
  const onClickClearHandler = () => {
    onChange?.('');

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  // 點擊放大鏡搜尋
  const onClickSearchHandler = () => {
    if (!inputRef.current) return;

    const keyword = (inputRef.current as HTMLInputElement).value;
    if (!keyword || keyword === '') return;

    const sanitizeKeyword = sanitize(keyword);

    onEnter?.(sanitizeKeyword);
  }

  const onChangeHandler = debounce((event: React.SyntheticEvent<HTMLInputElement>): void => {
    if (compositionStatus !== 'end') return;

    const keyword = (event.target as HTMLInputElement).value;
    onChange?.(keyword);
  }, 500, { 'maxWait': 1000 });

  // 按下 Enter 鍵搜尋
  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.nativeEvent.isComposing) return;

    if (event.keyCode === ENTER_KEY_CODE) {
      onClickSearchHandler();
    }
  };

  const onCompositionChangeHandler =
    (status: string) =>
    (event: React.SyntheticEvent<HTMLInputElement>): void => {
      compositionStatus = status;
      onChangeHandler(event);
    };

  return (
    <Container>
      <Input
        ref={inputRef}
        type="search"
        defaultValue={value}
        placeholder={placeholder}
        className={cx({ desktop: theme === 'desktop' })}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandler}
        onCompositionStart={onCompositionChangeHandler('start')}
        onCompositionEnd={onCompositionChangeHandler('end')}
      />

      <Button>
        {isShowClearButton && (
          <Icon
            alt='clear input icon'
            src='/assets/icons/close/search-close.svg'
            className='close'
            width={15}
            height={15}
            onClick={onClickClearHandler}
          />
        )}

        {isShowSearchButton && (
          <Icon
            alt='search icon'
            src='/assets/icons/search/icon-search-new.svg'
            className={cx('search', { desktop: theme === 'desktop' })}
            width={24}
            height={24}
            onClick={onClickSearchHandler}
          />
        )}
      </Button>
    </Container>
  );
});

export default AnueSearchInput;
