'use client';

import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import LinkFactory, { generateFundLink, generateStockLinkBySymbol } from '@fe-common-utils/libs/link';
import { getStockCategory, getStockCode, getStockExchange } from '@fe-common-utils/libs/stock';
import { styled } from '@linaria/react';
import { useCallback } from 'react';
import {
  AnueSearchResultProps,
  HotTagType,
  NewsResultType,
  RecentSearchType,
  TradeResultType
} from './AnueSearchResult.d';
import AnueSearchResultEmpty from './AnueSearchResultEmpty';
import {
  AnueSearchResultHotTagItem,
  AnueSearchResultHotTradeItem,
  AnueSearchResultNewsItem,
  AnueSearchResultRecentItem,
  AnueSearchResultTradeItem
} from './AnueSearchResultItems';
import AnueSearchResultWrapper from './AnueSearchResultWrapper';

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10;
  background-color: #fff;
  border-radius: 6px;

  @media only screen and (max-width: ${SCREEN_SIZE.MOBILE}px) {
    position: sticky;
    overflow-y: auto;
  }
`;

const Section = styled.div`
  @media only screen and (max-width: ${SCREEN_SIZE.MOBILE}px) {
    display: flex;
    flex-direction: column;
  }
`;

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  flex-wrap: wrap;
`;

const MAX_RECENT_SEARCH = 5;
const MAX_SEARCH_NEWS = 2;

const { newsTagLink } = LinkFactory;

const AnueSearchResult = ({
  searchString,
  hotTags,
  hotTrades,
  recentSearch,
  tradeResult,
  newsResult,
  onHotTagClick,
  onRecentSearchRowClick,
  onRecentSearchRowRemoveClick,
  onRecentSearchRemoveAllClick,
  onResultSearchRowClick
}: AnueSearchResultProps) => {
  const onHotTagClickHandler = useCallback(
    (tag: string) =>
      (event: React.SyntheticEvent): void => {
        event.preventDefault();
        onHotTagClick(tag);
      },
    [onHotTagClick]
  );

  // Display nothing if receive a empty array
  const renderHotTags = () => (
    <AnueSearchResultWrapper title='熱搜' isLoaded={(hotTags && hotTags.length > 0) || false}>
      {hotTags && hotTags.length > 0 ? (
        <TagContainer>
          {hotTags &&
            hotTags.map((hotTag: HotTagType) => {
              const { tag } = hotTag;
              const link = `${newsTagLink}/${tag}`;

              return (
                <AnueSearchResultHotTagItem
                  key={`search_result_hot_tag_item_${tag}`}
                  title={tag}
                  link={link}
                  onClick={onHotTagClickHandler(tag)}
                />
              );
            })}
        </TagContainer>
      ) : (
        <AnueSearchResultEmpty>沒有相關熱搜關鍵字</AnueSearchResultEmpty>
      )}
    </AnueSearchResultWrapper>
  );

  // Display nothing if receive a empty array
  const renderHotTrades = () => (
    <AnueSearchResultWrapper title='熱門行情' isLoaded={(hotTrades && hotTrades.length > 0) || false}>
      {hotTrades &&
        hotTrades.map(trade => {
          const { symbol, displayName } = trade;

          const code = getStockCode(symbol);
          const display = displayName || code;
          const link = generateStockLinkBySymbol(symbol);

          return display && link !== '' ? (
            <AnueSearchResultHotTradeItem key={`search_result_hot_trade_item_${code}`} title={display} link={link} />
          ) : null;
        })}
    </AnueSearchResultWrapper>
  );

  const onClickRecentSearch = (keyword: string) => (event: React.SyntheticEvent) => {
    event.preventDefault();

    onRecentSearchRowClick?.(keyword);
  };

  const onClickRecentSearchDelete = (keyword: string) => (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    onRecentSearchRowRemoveClick?.(keyword);
  };

  // Display nothing if receive a empty array
  const renderRecentSearch = () => (
    <AnueSearchResultWrapper
      title='最近搜尋'
      headerActionName={recentSearch && recentSearch.length > 0 ? '全部刪除' : ''}
      isLoaded={(recentSearch && recentSearch.length > 0) || false}
      onHeaderActionClick={onRecentSearchRemoveAllClick}
    >
      {recentSearch &&
        recentSearch.slice(0, MAX_RECENT_SEARCH).map((search: RecentSearchType) => {
          const { keyword } = search;

          return (
            <AnueSearchResultRecentItem
              key={`search_result_recent_item_${keyword}`}
              keyword={keyword}
              onClick={onClickRecentSearch(keyword)}
              onClickRemove={onClickRecentSearchDelete(keyword)}
            />
          );
        })}
    </AnueSearchResultWrapper>
  );

  const onClickSearchResultItem = (link: string) => (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    onResultSearchRowClick?.(link);
  };

  // Display the default message if receive a empty array.
  const renderSearchTradeResult = () => (
    <AnueSearchResultWrapper
      title='行情'
      more='看更多'
      isLoaded={(tradeResult && tradeResult.length > 0) || true}
      keyword={searchString}
    >
      {tradeResult && tradeResult.length > 0 ? (
        tradeResult.map((trade: TradeResultType) => {
          const { cnyesId, displayName, displayNameLocal, code, symbol, chName, enName, objectType } = trade;
          const isFund = objectType === 'FUND';
          const category = getStockCategory(trade);
          const exchange = getStockExchange(trade);
          const info = [category, exchange].filter(v => !!v).join('-');

          let tradeLink = generateStockLinkBySymbol(symbol);
          let tradeCode = code;
          let tradeChName = chName;
          let tradeEnName = enName;

          if (isFund) {
            tradeLink = generateFundLink(displayName, cnyesId);
            tradeCode = displayNameLocal;
            tradeChName = displayName;
            tradeEnName = '';
          }

          return (
            <AnueSearchResultTradeItem
              key={`search_result_trade_item_${tradeCode}`}
              link={tradeLink}
              code={tradeCode}
              info={info}
              chName={tradeChName}
              enName={tradeEnName}
              onClick={onClickSearchResultItem(tradeLink)}
            />
          );
        })
      ) : (
        <AnueSearchResultEmpty>沒有符合搜尋條件的資料</AnueSearchResultEmpty>
      )}
    </AnueSearchResultWrapper>
  );

  // Display the default message if receive a empty array.
  const renderSearchNewsResult = () => (
    <AnueSearchResultWrapper
      title='新聞'
      more='看更多'
      isLoaded={(newsResult && newsResult.length > 0) || true}
      keyword={searchString}
    >
      {newsResult && newsResult.length > 0 ? (
        newsResult.slice(0, MAX_SEARCH_NEWS).map((news: NewsResultType) => {
          const { newsId, title, publishAt } = news;
          const link = `${LinkFactory.newsDetailLink}/${newsId}`;

          return (
            <AnueSearchResultNewsItem
              key={`search_result_news_item_${newsId}`}
              link={link}
              title={title}
              publishAt={publishAt}
              onClick={onClickSearchResultItem(link)}
            />
          );
        })
      ) : (
        <AnueSearchResultEmpty>沒有符合搜尋條件的資料</AnueSearchResultEmpty>
      )}
    </AnueSearchResultWrapper>
  );

  return (
    <Container>
      {searchString ? (
        <Section>
          {renderSearchTradeResult()}
          {renderSearchNewsResult()}
        </Section>
      ) : (
        <Section>
          {renderHotTags()}
          {renderHotTrades()}
          {renderRecentSearch()}
        </Section>
      )}
    </Container>
  );
};
export default AnueSearchResult;
