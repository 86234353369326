'use client';

import dynamic from 'next/dynamic';

type AnueTopBarProps = {
  hasSmartBanner?: boolean;
  newsId?: number;
};

const DynamicMobileTopBar = dynamic(() => import('./MobileTopBar'), {
  ssr: false
});

const AnueTopBar: React.FC<AnueTopBarProps> = ({ hasSmartBanner = false, newsId = 0 }) => {
  return <DynamicMobileTopBar hasSmartBanner={hasSmartBanner} newsId={newsId} />;
};

export default AnueTopBar;
