'use client';

import { CustomEventName, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { createCookie, getCookie, removeCookie } from '@fe-common-utils/libs/cookie';
import { generateSearchResultLink } from '@fe-common-utils/libs/link';
import sanitize from '@fe-common-utils/libs/sanitize';
import { isClient } from '@fe-common-utils/libs/utils';
import { styled } from '@linaria/react';
import cx from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import AnueSearchInput from '../AnueSearchInput';
import AnueSearchResult from '../AnueSearchResult';
import {
  HotTagType,
  HotTradesType,
  NewsResultType,
  RecentSearchType,
  TradeResultType
} from '../AnueSearchResult/AnueSearchResult.d';
import { GETHotQuote, GETRecommendTags, GETSearchKeyword } from '../apis/search';

const View = styled.div`
  position: relative;
  width: 100%;

  > * {
    z-index: 5;
  }
`;

//
// 1024px 以上的都歸類為桌機，用的會是桌機的設計
//
// How to remove the border highlight on an input text element
// https://stackoverflow.com/questions/1457849/how-to-remove-the-border-highlight-on-an-input-text-element
const Container = styled.div`
  &.active {
    @media only screen and (min-width: ${SCREEN_SIZE.MOBILE}px) {
      input {
        background-color: #fff;
        box-shadow: 0 0 2px 2px rgb(193, 246, 255);
        border: 1px solid #3ec1ce;
      }

      input:focus {
        outline: none;
      }
    }
  }
`;

const Result = styled.div`
  display: none;
  position: fixed;
  top: 43px;
  left: 0;
  right: 0;
  bottom: auto;
  border-radius: 6px;
  border: 1px solid rgba(0, 65, 143, 0.1);
  box-shadow: 0 0 6px 0 rgba(0, 65, 143, 0.2);
  overflow-y: auto;

  @media only screen and (min-width: ${SCREEN_SIZE.MOBILE}px) {
    width: 100%;
    position: absolute;
  }

  &.display {
    display: block;
  }
`;

type AnueSearchProps = {
  // maxRecentHistory: number;
  placeholder?: string | undefined;
  defaultValue?: string | undefined;
  keyword?: string | undefined;
  theme?: 'desktop' | 'mobile';
  // shouldAlwaysDisplayInput?: boolean | undefined;
  // shouldDisplayBackIcon?: boolean | undefined;
  // onInputBlur: () => void;
  aink?: string | undefined;
};

type AnueSearchResultProps = {
  hotTags: HotTagType[];
  hotTrades: HotTradesType[];
  trades: TradeResultType[];
  news: NewsResultType[];
  recentSearch: RecentSearchType[];
};

const DEFAULT_HOT_TAGS_AND_TRADES: AnueSearchResultProps = {
  hotTags: [],
  hotTrades: [],
  trades: [],
  news: [],
  recentSearch: []
};

const COOKIE_NAME = 'recent';

async function fetchSearchKeyword(keyword: string | undefined) {
  const result = {
    news: [],
    quoteFunds: []
  };

  try {
    if (!keyword) return result;

    const response = await GETSearchKeyword(keyword);
    return response.data ?? result;
  } catch (error) {
    console.error('[Header全站搜尋API錯誤]:', error);

    return result;
  }
}

async function fetchHotTags() {
  try {
    const response = await GETRecommendTags();

    return response?.items;
  } catch (error) {
    console.error('[Header熱搜標籤API錯誤]:', error);

    return [];
  }
}

async function fetchHotTrades() {
  try {
    const response = await GETHotQuote();

    return response?.data;
  } catch (error) {
    console.error('[Header熱門行情API錯誤]:', error);

    return [];
  }
}

async function fetchHotTagAndTrades(): Promise<AnueSearchResultProps> {
  try {
    const response = await Promise.allSettled([fetchHotTags(), fetchHotTrades()]);

    if (response.length === 2) {
      return {
        ...DEFAULT_HOT_TAGS_AND_TRADES,
        hotTags: response[0].status === 'fulfilled' ? response[0].value : [],
        hotTrades: response[1].status === 'fulfilled' ? response[1].value : []
      };
    }

    return DEFAULT_HOT_TAGS_AND_TRADES;
  } catch (error) {
    console.error('[Header熱門行情API錯誤]:', error);

    return DEFAULT_HOT_TAGS_AND_TRADES;
  }
}

/**
 * 將搜尋關鍵字儲存到最近搜尋歷史記錄中，也就是 cookie 中
 * @param keyword 搜尋關鍵字
 */
function storeKeywordToCookie(keyword: string): void {
  const cookies = getCookie(COOKIE_NAME) || [];
  const keywords = cookies.map((item: RecentSearchType) => item.keyword);
  const storeKeywords = new Set([...keywords, keyword]);
  const recentSearchKeywords = Array.from(storeKeywords).map(keyword => ({ keyword }));
  createCookie({ key: COOKIE_NAME, value: [...recentSearchKeywords] });
}

function getSearchHistory(): RecentSearchType[] {
  return getCookie(COOKIE_NAME);
}

function deleteSearchHistory(keyword: string): RecentSearchType[] {
  if (keyword === '') {
    removeCookie(COOKIE_NAME);

    return [];
  } else {
    const currentSearchHistory = getSearchHistory();
    const newSearchHistory = currentSearchHistory.filter(item => item.keyword !== keyword);
    createCookie({ key: COOKIE_NAME, value: newSearchHistory });

    return newSearchHistory;
  }
}

function redirectToSearchResultPage(keyword: string, backLink: string): void {
  if (!isClient) return;

  const link = generateSearchResultLink({ keyword, backLink });

  window.location.href = link;
}

const AnueSearch = ({
  placeholder = '搜尋新聞、行情代碼或名稱',
  keyword,
  defaultValue,
  theme = 'desktop',
  // maxRecentHistory = 10,
  // shouldAlwaysDisplayInput = false,
  // shouldDisplayBackIcon = false,
  aink = ''
}: AnueSearchProps) => {
  const searchInputReference = useRef<HTMLInputElement>(null);

  const [searchString, setSearchString] = useState<string | undefined>(keyword || defaultValue || undefined);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [data, setData] = useState<AnueSearchResultProps>(DEFAULT_HOT_TAGS_AND_TRADES);

  useEffect(() => {
    const fetch = async () => {
      const result = await fetchSearchKeyword(searchString);

      setData(previousData => ({
        ...previousData,
        trades: result.quoteFunds,
        news: result.news
      }));
    };

    if (searchString !== '') {
      fetch();
    } else {
      setData(previousData => ({
        ...previousData,
        trades: [],
        news: []
      }));
    }

    sendGTM({
      [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK,
      [GTMEventKey.SECTION]: 'SearchAll',
      [GTMEventKey.CLICK_ITEM]: searchString
    });
  }, [searchString]);

  /**
   * 點擊搜尋輸入框時，先打 API 取得熱門標籤和行情
   */
  useEffect(() => {
    const fetch = async () => {
      const hotTagAndTrades = await fetchHotTagAndTrades();

      if (hotTagAndTrades.hotTags.length > 0 || hotTagAndTrades.hotTrades.length > 0) {
        const recentSearch = getSearchHistory();

        setData(previousData => ({
          ...previousData,
          ...hotTagAndTrades,
          recentSearch
        }));
      }
    };

    // 展開搜尋框才打API,且開關搜尋框不重打API
    if (isFocus && data.hotTags && data.hotTags.length === 0) {
      fetch();
    }
  }, [isFocus, data]);

  useEffect(() => {
    const keywordQueryStringRegex =
      isClient && window.location.search && sanitize(window.location.search).match(/keyword=([^&]*)/);

    if (keywordQueryStringRegex && keywordQueryStringRegex[1]) {
      setSearchString(keywordQueryStringRegex[1]);
    }
  }, []);

  // useEffect(() => {
  //   const bodyStyles = document.body.style;
  //   const screenWidth = window.innerWidth || document.documentElement.clientWidth;
  //   const isSmallMode = theme === 'mobile' || screenWidth < styles.mobileScreenWidth;

  //   if (isClient && hover !== hover) {
  //     if (hover && isSmallMode) {
  //       document.body.style.overflowY = 'hidden';
  //     } else if (bodyStyles) {
  //       document.body.style.overflowY = 'auto';
  //     }
  //   }
  // }, [hover, theme]);

  /**
   * 直接點擊熱門標籤時，將標籤名稱填入搜尋框進行快速搜尋
   */
  const onHotTagClickHandle = useCallback((tag: string): void => {
    if (!tag) return;

    setSearchString(tag);

    searchInputReference?.current?.focus();
  }, []);

  /**
   * 點擊任一搜尋歷史紀錄進行快速搜尋
   */
  const onClickRecentTagHandler = useCallback((tag: string): void => {
    redirectToSearchResultPage(tag, aink);
  }, []);

  /**
   * 刪除單一搜尋歷史紀錄
   */
  const onClickRemoveTagHandler = useCallback((tag: string): void => {
    const recentSearch = deleteSearchHistory(tag);

    setData(previousData => ({
      ...previousData,
      recentSearch
    }));
  }, []);

  /**
   * 刪除全部搜尋歷史紀錄
   */
  const onClickRemoveAllTagsHandler = useCallback((): void => {
    const recentSearch = deleteSearchHistory('');

    setData(previousData => ({
      ...previousData,
      recentSearch
    }));
  }, []);

  const onSearchInputChangeHandler = useCallback((value: string): void => {
    setSearchString(value);
  }, []);

  //   const handleClickBackIcon = () => {
  //     setHover(false);
  //     onBackIconClick();
  //   };

  const onSearchInputFocusHandler = useCallback((): void => {
    setIsFocus(true);
  }, []);

  const onSearchInputBlurHandler = useCallback((): void => {
    setIsFocus(false);
    // onInputBlur?.();
  }, []);

  /**
   * 按下 Enter 鍵搜尋關鍵字時，將搜尋關鍵字儲存到 cookie 中
   */
  const onSearchEnterHandler = useCallback((keyword: string): void => {
    if (!keyword || !isClient) return;

    sendGTM({
      [GTMEventKey.EVENT_NAME]: CustomEventName.SEARCH,
      [GTMEventKey.SECTION]: 'Search',
      [GTMEventKey.CLICK_ITEM]: keyword
    });

    storeKeywordToCookie(keyword);
    redirectToSearchResultPage(keyword, aink);
  }, []);

  /**
   * 點擊任一搜尋結果項目，都會將搜尋關鍵字儲存到 cookie 中
   */
  const onClickResultSearchItemHandler = useCallback(
    (link: string): void => {
      if (!searchString || !isClient) return;

      sendGTM({
        [GTMEventKey.EVENT_NAME]: CustomEventName.SEARCH,
        [GTMEventKey.SECTION]: 'Search',
        [GTMEventKey.CLICK_ITEM]: searchString
      });

      storeKeywordToCookie(searchString);
      window.location.href = link;
    },
    [searchString]
  );

  const handleStopWrapperClickPropagation = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
  };

  const isNotEmpty = typeof searchString !== 'undefined' && searchString !== '';

  return (
    <View onClick={handleStopWrapperClickPropagation}>
      <Container className={cx({ active: isFocus })}>
        <AnueSearchInput
          ref={searchInputReference}
          placeholder={placeholder}
          value={searchString}
          onFocus={onSearchInputFocusHandler}
          onChange={onSearchInputChangeHandler}
          onBlur={onSearchInputBlurHandler}
          onEnter={onSearchEnterHandler}
          isShowClearButton={isNotEmpty}
          isShowSearchButton
          theme={theme}
          // onBackIconClick={handleClickBackIcon}
        />
      </Container>
      <Result className={cx({ display: isFocus })}>
        <AnueSearchResult
          searchString={searchString}
          hotTags={data.hotTags}
          hotTrades={data.hotTrades}
          tradeResult={data.trades}
          newsResult={data.news}
          recentSearch={data.recentSearch}
          onHotTagClick={onHotTagClickHandle}
          onRecentSearchRowClick={onClickRecentTagHandler}
          onRecentSearchRowRemoveClick={onClickRemoveTagHandler}
          onRecentSearchRemoveAllClick={onClickRemoveAllTagsHandler}
          onResultSearchRowClick={onClickResultSearchItemHandler}
        />
      </Result>
    </View>
  );
};

export default AnueSearch;
