'use client';

import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { styled } from '@linaria/react';
import Link from 'next/link';

const DEFAULT_BACKGROUND = '#e2e8f1';
const DEFAULT_HEIGHT = 40;

const View = styled.div<{ background?: string; height?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ background }) => (background ? background : DEFAULT_BACKGROUND)};
  width: 100%;
  height: ${({ height }) => (height ? height : DEFAULT_HEIGHT)}px;
`;

const Container = styled.ul<{ background?: string; height?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ background }) => (background ? background : DEFAULT_BACKGROUND)};
  font-size: 15px;
  color: #515c69;
  max-width: ${SCREEN_SIZE.FULL}px;
  width: 100%;
  height: ${({ height }) => (height ? height : DEFAULT_HEIGHT)}px;
`;

const Tab = styled.li<{ active?: boolean }>`
  && a {
    color: ${({ active }) => (active ? '#e03f19' : '#515c69')};
    background-color: ${({ active }) => (active ? '#ffffffb3' : 'transparent')};
    padding: 12px 14px;

    &:hover {
      color: #e03f19;
      background-color: #ffffffb3;
    }
  }
`;

interface HeaderSubTabProp {
  id: string;
  name: string;
  link?: string | undefined;
}
interface HeaderSubTabsProps {
  data: HeaderSubTabProp[];
  value?: string;
  options?:
    | {
        background?: string | undefined;
        height?: number | undefined;
      }
    | undefined;
}

export const HeaderSubTabs = ({
  data = [],
  value = '',
  options = { background: DEFAULT_BACKGROUND, height: DEFAULT_HEIGHT }
}: HeaderSubTabsProps) => {
  const { background, height } = options;

  return (
    <View background={background} height={height}>
      <Container>
        {data.map(({ name, link, id }) => (
          <Tab key={`sub_tab_${id}`} active={value === id}>
            <Link href={link ?? '/'}>{name}</Link>
          </Tab>
        ))}
      </Container>
    </View>
  );
};

export default HeaderSubTabs;
