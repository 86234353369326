'use client';

import { generateSearchResultLink } from '@fe-common-utils/libs/link';
import { postTimeDisplayFactory } from '@fe-common-utils/libs/time';
import { styled } from '@linaria/react';
import cx from 'classnames';

const SearchContainer = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  color: #1e1e1e;
  z-index: 1;
  line-height: 1;
  min-height: 100%;
  margin: 0 8px;
  border-bottom: 1px solid rgba(0, 65, 143, 0.1);
  background-color: #fff;
  padding: 12px 0;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.5px;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }

  .title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
    font-size: 15px;
    color: #1e1e1e;
    min-height: 100%;
    margin-bottom: 6px;

    > small {
      font-size: 12px;
      color: #909090;
      letter-spacing: 0.5px;
      margin-left: 8px;
      font-size: 10px;
      transform: scale(0.85) translate(-10%);

      &:before {
        content: '/';
        font-size: 10px;
        font-weight: normal;
        margin-right: 4px;
      }
    }
  }

  .summary {
    font-size: 13px;
    color: #909090;

    > span {
      margin-right: 0;
      font-size: 12px;
      font-weight: normal;

      &.mark {
        margin-left: 5px;

        &:first-child {
          margin-left: 0;
        }
      }

      &.time {
        margin-right: 0;
        display: flex;
        align-items: center;

        &:before {
          content: '';
          width: 12px;
          height: 12px;
          margin-right: 6px;
          background: url('/assets/icons/time/icon-time.svg') no-repeat;
        }
      }
    }
  }

  .mark {
    mark {
      background-color: transparent;
      color: #e03f19;
    }
  }

  &.with-hot {
    position: relative;
    padding-left: calc(11px + 8px);
    font-size: 16px;

    &::before {
      content: '';
      position: absolute;
      width: 11px;
      height: 14px;
      left: 0;
      background: url('/assets/icons/hot/fire.svg') center no-repeat;
    }
  }

  &.with-delete {
    display: flex;

    p,
    span,
    a {
      max-width: 90%;
    }

    .delete {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 16%;
      height: 100%;
      z-index: 2;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        top: 12px;
        right: 0;
        left: 0;
        margin: 0 auto;
        width: 1px;
        height: 12px;
        background-color: #000;
        transform: rotate(45deg);
      }

      &::after {
        content: '';
        position: absolute;
        top: 12px;
        right: 0;
        left: 0;
        margin: 0 auto;
        width: 1px;
        height: 12px;
        background-color: #000;
        transform: rotate(135deg);
      }
    }
  }
`;

const HotContainer = styled.a`
  font-size: 15px;
  font-weight: 300;
  line-height: 1;
  color: #0073ff;
  text-decoration: underline !important;
  margin: 8px;
  cursor: pointer;
`;

// 新聞搜尋結果

type AnueSearchResultTradeItemProps = {
  link: string;
  code: string;
  info: string;
  chName: string;
  enName: string;
  onClick?: (event: React.SyntheticEvent) => void;
};

export const AnueSearchResultTradeItem = ({
  link,
  code = '',
  info = '',
  chName = '',
  enName = '',
  onClick
}: AnueSearchResultTradeItemProps) => {
  return (
    <SearchContainer href={link} onClick={onClick && onClick}>
      <p className={cx('title')}>
        <span className={cx('mark')} dangerouslySetInnerHTML={{ __html: code }} />
        <small>{info}</small>
      </p>
      <p className={cx('summary')}>
        <span className={cx('mark')} dangerouslySetInnerHTML={{ __html: chName }} />
        <span className={cx('mark')} dangerouslySetInnerHTML={{ __html: enName }} />
      </p>
    </SearchContainer>
  );
};

// 新聞搜尋結果

type AnueSearchResultNewsItemProps = {
  link: string;
  title: string;
  publishAt: number;
  onClick?: (event: React.SyntheticEvent) => void;
};

export const AnueSearchResultNewsItem = ({
  link,
  title = '',
  publishAt = 0,
  onClick
}: AnueSearchResultNewsItemProps) => {
  const postTime = postTimeDisplayFactory(publishAt * 1000);

  return (
    <SearchContainer href={link} onClick={onClick && onClick}>
      <p className={cx('title')}>
        <span className={cx('mark')} dangerouslySetInnerHTML={{ __html: title }} />
      </p>
      <p className={cx('summary')}>
        <span className={cx('time')}>{postTime.display}</span>
      </p>
    </SearchContainer>
  );
};

// 熱門搜尋關鍵字

type AnueSearchResultHotTagItemProps = {
  title: string;
  link: string;
  isHot?: boolean;
  onClick?: (event: React.SyntheticEvent) => void;
};

export const AnueSearchResultHotTagItem = ({ title, link, onClick }: AnueSearchResultHotTagItemProps) => {
  return (
    <HotContainer href={link} onClick={onClick && onClick}>
      {title}
    </HotContainer>
  );
};

// 熱門股票/基金

type AnueSearchResultHotTradeItemProps = {
  title: string;
  link: string;
  onClick?: (event: React.SyntheticEvent) => void;
};

export const AnueSearchResultHotTradeItem = ({ title, link, onClick }: AnueSearchResultHotTradeItemProps) => {
  return (
    <SearchContainer href={link} onClick={onClick && onClick} className={cx('with-hot')}>
      {title}
    </SearchContainer>
  );
};

// 最近搜尋

type AnueSearchResultRecentItemProps = {
  keyword: string;
  onClick?: (event: React.SyntheticEvent) => void;
  onClickRemove?: (event: React.SyntheticEvent) => void;
};

export const AnueSearchResultRecentItem = ({ keyword, onClick, onClickRemove }: AnueSearchResultRecentItemProps) => {
  const link = generateSearchResultLink({ keyword });

  return (
    <SearchContainer href={link} onClick={onClick && onClick} className={cx('with-delete')}>
      {keyword}
      <button className={cx('delete')} onClick={onClickRemove} />
    </SearchContainer>
  );
};
