'use client';

import { CategoryContext } from '@fe-news/context/category-provider';
import { useContext } from 'react';

export const useCategoryContext = () => {
  const categoryContext = useContext(CategoryContext);

  return categoryContext;
};
