'use client';

import { CustomEventName, GTMEventKey, sendGTM } from '@fe-cnyes/fe-common-gtm';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { generateLink } from '@fe-common-utils/libs/link';
import { styled } from '@linaria/react';
import cx from 'classnames';
import dynamic from 'next/dynamic';
import { createRef, useMemo, useRef, useState } from 'react';
import AnueSearch from '../AnueSearch/AnueSearch';

const DynamicAnueSubMenu = dynamic(() => import('./AnueSubMenu'), {
  ssr: false
});

const Header = styled.header`
  background-color: white;
  height: 50px;
  width: 100%;
  box-shadow: 0 2px 6px 0 rgba(0, 65, 143, 0.1);
  padding: 5px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    height: 2px;
    right: 0;
    left: 0;
    top: 0;
    background: linear-gradient(to right, #54b5c6 60%, #e03f19 60%, #e03f19 90%, #ffb43e 90%);
    z-index: 2;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  width: 1200px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  margin-right: auto;
  margin-left: auto;
  position: relative;
`;

const Logo = styled.a`
  background-size: cover;
  background: url('/assets/icons/logo/anue-colorful.svg') center no-repeat;
  display: inline-block;
  width: 63px;
  height: 26px;
  margin-right: 12px;
`;

const Channels = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const Channel = styled.li`
  list-style-type: none;
  text-align: center;
  position: relative;
  cursor: pointer;
  padding: 18px 10px 14px 10px;

  span {
    display: block;
    font-size: 15px;
    line-height: 20px;
    color: #383838;

    &.new {
      &::before {
        content: 'NEW';
        position: absolute;
        display: inline-block;
        width: 38px;
        line-height: 12px;
        padding: 3px 4px;
        text-align: center;
        border-radius: 4px;
        background-color: #feede9;
        font-size: 9px;
        font-weight: 500;
        letter-spacing: 0.28px;
        color: #e03f19;
        top: 4px;
        left: 30%;
        transform: scale(0.75) translate(-30%);
      }

      ::after {
        clear: both;
      }
    }
  }
`;

const Anchor = styled.a<{ active: boolean; current: boolean }>`
  display: block;
  font-size: 15px;
  line-height: 20px;
  color: #383838;

  :hover {
    font-weight: 500;
  }

  &.active {
    font-weight: ${({ active }) => (active ? 500 : 400)};
  }

  &.current {
    font-weight: ${({ current }) => (current ? 700 : 400)};
  }

  &.new {
    &::before {
      content: 'NEW';
      position: absolute;
      display: inline-block;
      width: 38px;
      line-height: 12px;
      padding: 3px 4px;
      text-align: center;
      border-radius: 4px;
      background-color: #feede9;
      font-size: 9px;
      font-weight: 500;
      letter-spacing: 0.28px;
      color: #e03f19;
      top: 4px;
      left: 30%;
      transform: scale(0.75) translate(-30%);
    }

    ::after {
      clear: both;
    }
  }
`;

const Search = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 244px;
  position: relative;

  @media screen and (max-width: ${SCREEN_SIZE.FULL}px) {
    max-width: 244px;
  }

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    margin-left: 6px;
    margin-right: 13px;
    max-width: 100%;
  }

  @media screen and (max-width: ${SCREEN_SIZE.MOBILE}px) {
    margin-left: 6px;
    margin-right: 6px;
    max-width: 100%;
  }

  .expand {
    position: absolute;
    right: 0;
    max-width: 314px;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 154px;
`;

const DEFAULT_CHANNEL_INDEX = -1;

type SubItemProps = {
  name: string;
  title: string;
  url?: string;
  blank?: boolean;
  enable?: boolean;
  highlight?: boolean;
  subItems?: SubItemProps[];
};

type NavProps = {
  name: string;
  title: string;
  url?: string;
  isNew?: boolean;
  blank?: boolean;
  enable?: boolean;
  subItems?: SubItemProps[][];
};

type AnueNavigatorProps = {
  channelName?: string;
  logoUrl: string;
  navs: NavProps[];
  searchKeyword?: string;
  apiEnv?: string;
  enabledSearch?: boolean;
  Bell: React.ReactNode;
  Account: React.ReactNode;
  SubNavs?: React.ReactNode;
};

type NavigatorProps = {
  channelName: string;
  channels: NavProps[];
  headerRef: React.MutableRefObject<null>;
  apiEnv?: string;
};

function onLogoClick() {
  return sendGTM({
    [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK,
    [GTMEventKey.CLICK_ITEM]: 'Logo'
  });
}

function onChannelClick(channel: string) {
  return () =>
    sendGTM({
      [GTMEventKey.EVENT_NAME]: CustomEventName.CLICK_NAV,
      [GTMEventKey.CLICK_ITEM]: channel
    });
}

const Navigator = ({ channelName, channels, headerRef }: NavigatorProps) => {
  const channelRefs: React.RefObject<HTMLLIElement>[] = useMemo(
    () =>
      Array(channels.length)
        .fill(0)
        .map(i => createRef()),
    [channels.length]
  );

  const channelLink: string[] = useMemo(
    () => channels.map(({ url }) => (url !== '' ? generateLink(url) : '')),
    [channels]
  );

  const [currentIndex, setCurrentIndex] = useState<number>(DEFAULT_CHANNEL_INDEX);

  const channelMouseEnterHandler = (index: number) => () => {
    currentIndex !== index && setCurrentIndex(index);
  };

  return (
    <Channels>
      {channels?.map(({ name, title, enable, blank, subItems, isNew }: NavProps, index) => {
        if (!enable) return null;

        const isHasSubMenu = (subItems && subItems?.length > 0) || false;
        const isActive = index === currentIndex;
        const isCurrentChannel = channelName === title;
        const link = channelLink[index];
        const isShowSubMenu = isHasSubMenu && index === currentIndex;

        return (
          <Channel
            key={name}
            ref={channelRefs[index]}
            onMouseEnter={channelMouseEnterHandler(index)}
            onMouseLeave={channelMouseEnterHandler(DEFAULT_CHANNEL_INDEX)}
          >
            {link ? (
              <Anchor
                active={isActive}
                current={isCurrentChannel}
                href={link}
                target={blank ? '_blank' : '_self'}
                rel='noopener noreferrer'
                className={cx({ new: isNew, active: isActive, current: isCurrentChannel })}
                onClick={onChannelClick(title)}
              >
                {title}
              </Anchor>
            ) : (
              <span className={cx({ new: isNew })}>{title}</span>
            )}
            {headerRef && isShowSubMenu && (
              <DynamicAnueSubMenu
                key={`${name}_sub_menus`}
                parentName={title}
                headerRef={headerRef}
                parentRef={channelRefs[index]}
                data={subItems || []}
              />
            )}
          </Channel>
        );
      })}
    </Channels>
  );
};

export const AnueNavigator = ({
  channelName = '首頁',
  logoUrl = '',
  navs = [],
  searchKeyword = '',
  enabledSearch = true,
  Bell,
  Account,
  SubNavs
}: AnueNavigatorProps) => {
  const headerRef = useRef(null);

  return (
    <>
      <Header>
        <Container ref={headerRef}>
          <Logo href={logoUrl} onClick={onLogoClick} />
          <Navigator channelName={channelName} channels={navs} headerRef={headerRef} />
          {enabledSearch && (
            <Search>
              <AnueSearch keyword={searchKeyword} />
            </Search>
          )}
          <Info>
            {Bell && Bell}
            {Account && Account}
          </Info>
        </Container>
      </Header>
      {SubNavs && SubNavs}
    </>
  );
};

export default AnueNavigator;
