type IconProps = {
  width?: number;
  height?: number;
  animationSpeed?: number;
  color?: string;
  animate?: boolean;
  hover?: boolean;
  enableBadge?: boolean;
  isLogin?: boolean;
};

const Icon = (props: IconProps) => {
  const {
    width = '24',
    height = '24',
    animationSpeed = '0.3',
    color = '#383838',
    animate,
    hover,
  } = props;

  const behavior = animate
    ? hover
      ? { begin: 'bell.mouseover', end: 'bell.mouseout' }
      : { begin: '0s' }
    : null;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      style={{ overflow: 'initial' }}
    >
      <g stroke={color} strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#fff">
          {animate ? (
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              keyTimes="0; 0.5; 1"
              values="-12 10 -4; 12 10 4; -12 10 -4;"
              dur={`${animationSpeed}s`}
              repeatCount="8"
              {...behavior}
            />
          ) : null}
          <path
            id="bell"
            d="M12 4.222a6.433 6.433 0 0 1 6.429 6.663l-.133 3.708 1.132 1.94a2 2 0 0 1-1.399 2.98l-1.585.265c-2.942.49-5.946.49-8.888 0l-1.585-.264a2 2 0 0 1-1.4-2.98l1.133-1.941-.133-3.708A6.433 6.433 0 0 1 12 4.222z"
          />
          <path d="M9.778 19.778a2.222 2.222 0 0 0 4.444 0M10.519 4.445v-.964a1.481 1.481 0 0 1 2.962 0v1.022" />
        </g>
      </g>
    </svg>
  );
};

export default Icon;
