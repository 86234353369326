'use client';

import 'anue-fe-sdk';
import 'anue-fe-sdk/web-preset';

// 上方這行空白是為了避免 prettier 的 import 排序，會將 anue-fe-sdk 排到最後，造成無法正確引入 anue 的錯誤
import NavigatorDesktop from '@fe-news/components/molecules/NavigatorDesktop/NavigatorDesktop';
import NavigatorMobile from '@fe-news/components/molecules/NavigatorMobile/NavigatorMobile';
import { styled } from '@linaria/react';

type Props = {
  channel: string;
  isHideLoginForIe?: boolean;
  className?: string;
  location?: {
    query?: {
      keyword?: string;
      aink?: string;
    };
    pathname?: string;
  };
  isShowShadowPlaceholder?: boolean;
  isEnableSmartBanner?: boolean;
  smartBannerGaEvent?: {
    dataPrefix?: string[];
    category?: string;
    action?: string;
    label?: string;
  };
  isMobileDevice?: boolean;
};

const Container = styled.header`
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
`;

const AnueHeader = ({
  channel,
  isHideLoginForIe = false,
  location,
  isShowShadowPlaceholder,
  isEnableSmartBanner = false,
  smartBannerGaEvent,
  isMobileDevice = false
}: Props): JSX.Element => {
  const queryKeyword = location?.query?.keyword ?? '';

  return (
    <Container id='anue-header'>
      <NavigatorDesktop
        channel={channel}
        isHideLoginForIe={isHideLoginForIe}
        isShowShadowPlaceholder={isShowShadowPlaceholder}
        keyword={queryKeyword}
      />
      <NavigatorMobile
        keyword={queryKeyword}
        smartBannerGaEvent={smartBannerGaEvent}
        isEnableSmartBanner={isEnableSmartBanner}
        isMobileDevice={isMobileDevice}
      />
    </Container>
  );
};

export default AnueHeader;
