/* eslint-disable @next/next/no-img-element */
'use client';

import accountNavigatorConfig from '@fe-news/configs/account-navigator.json';
import mobileHeaderConfig from '@fe-news/configs/mobile-header.json';
import { BellIcon } from '@fe-common-ui/Icons';
import { MobileHeader } from '@fe-common-ui/MobileHeader';
import { loadSmartBannerIsCloseFromCookie } from '@fe-common-ui/SmartBanner/SmartBanner';
import { getBaseUrlEnv } from '@fe-common-utils/libs/environment';
import LinkFactory from '@fe-common-utils/libs/link';
import AnueTopBar from '@fe-news/components/AnueTopBar/AnueTopBar';
import useAuth, { getProfile, init, login, logout } from '@fe-news/hooks/auth';
import { styled } from '@linaria/react';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import { FC, useEffect, useState } from 'react';

const DynamicAccountPanel = dynamic(() => import('@fe-common-ui/AccountPanel'), {
  // loading: () => <p>Loading...</p>
  ssr: false
});
const DynamicMobileMenu = dynamic(() => import('@fe-common-ui/MobileHeader/MobileMenu').then(menu => menu.MobileMenu), {
  ssr: false
});
const DynamicAnueAccount = dynamic(() => import('@fe-common-ui/AnueAccount'), {
  ssr: false
});

const accountMenus = accountNavigatorConfig.items;

interface Props {
  keyword: string;
  isEnableSmartBanner: boolean;
  smartBannerGaEvent?: {
    dataPrefix?: string[];
    category?: string;
    action?: string;
    label?: string;
  };
  isMobileDevice: boolean;
}

const MobileHeaderContainer = styled.div``;

const MenuLink = styled.a`
  height: 24px;
`;

const panelLogoutHandler = () => {
  logout();
};

const NavigatorMobile: FC<Props> = ({
  keyword = '',
  smartBannerGaEvent = {},
  isEnableSmartBanner,
  isMobileDevice
}): JSX.Element => {
  const { anueHaoLink, newsChannelLink } = LinkFactory;

  const [isCatBoardOpen, setIsCatBoardOpen] = useState<boolean>(false);
  const [isShowSmartBanner, setIsShowSmartBanner] = useState<boolean>(isEnableSmartBanner);
  const [isAccountPanelOpen, setIsAccountPanelOpen] = useState<boolean>(false);

  const pathName = usePathname();

  const isNewsDetailInMobile = pathName.includes('/news/id/') && isMobileDevice;

  const newsId = pathName.split('/').pop();

  const [status] = useAuth();

  const cnyesBaseUrl = process.env.NEXT_PUBLIC_CNYES_BASE_URL || '';
  const apiEnv = getBaseUrlEnv();

  const isLogin: boolean = status === 'login';
  const profile = getProfile() || { name: '登入', avatar: '' };

  /**
   * 用來載入會員目前登入資訊
   */
  useEffect(() => {
    // 一開始就決定是否要有 smart banner
    const smartBannerIsShow = !loadSmartBannerIsCloseFromCookie();

    setIsShowSmartBanner(smartBannerIsShow);
    init();
  }, []);

  const onCloseSmartBanner = () => {
    setIsShowSmartBanner(false);
  };

  const onShowOrHideCatBoard = (enable: boolean) => {
    return () => {
      setIsCatBoardOpen(enable);
    };
  };

  const onShowOrHideAccountPanel = (enable: boolean) => {
    return () => {
      setIsAccountPanelOpen(enable);
    };
  };

  const panelLoginHandler = () => {
    if (!isLogin) {
      login();
    }
  };

  // TODO: 待補上新版 ga 做法
  // TODO: 圖片路徑
  return (
    <MobileHeaderContainer>
      <div className='mobile-header'>
        <MobileHeader
          env={apiEnv}
          onShowCatBoard={onShowOrHideCatBoard(true)}
          LinksBarComponent={
            <>
              <MenuLink
                href='/poptopic'
                target='_blank'
                rel='noopener noreferrer'
                data-global-ga-category='Header'
                data-global-ga-action='click'
                data-global-ga-label='熱門時事'
              >
                <img
                  src='/assets/icons/icon-poptopic.svg'
                  alt='熱門時事'
                  title='/poptopic'
                  width={24}
                  height={24}
                  loading='lazy'
                />
              </MenuLink>
              <MenuLink
                href={'https://hao.cnyes.com/'}
                data-global-ga-category='Header'
                data-global-ga-action='click'
                data-global-ga-label='鉅亨號'
              >
                <img
                  src='/assets/icons/header/hao.png'
                  alt='鉅亨號'
                  title={anueHaoLink}
                  width={24}
                  height={24}
                  loading='lazy'
                />
              </MenuLink>
              <MenuLink
                href='https://campaign.cnyes.com/topics/anuecrypto/?utm_source=mobile&utm_medium=header'
                target='_blank'
                rel='noopener noreferrer'
                data-global-ga-category='Header'
                data-global-ga-action='click'
                data-global-ga-label='鉅亨買幣'
              >
                <img
                  src='/assets/icons/header/crypto.png'
                  alt='鉅亨買幣'
                  title='https://campaign.cnyes.com/topics/anuecrypto/?utm_source=mobile&utm_medium=header'
                  width={24}
                  height={24}
                  loading='lazy'
                />
              </MenuLink>
            </>
          }
          BellComponent={<BellIcon isLogin={isLogin} animate={false} href='https://www.cnyes.com/events' />}
          AccountComponent={
            <DynamicAnueAccount
              isMobile
              onClick={onShowOrHideAccountPanel(true)}
              isLogin={isLogin}
              avatarSrc={profile.avatar}
            />
          }
          shouldDisplayBackIcon
          searchKeyword={keyword}
          withSmartBanner={isShowSmartBanner}
          // SmartBanner 問題還沒修好前，先暫時不顯示 (ANUE-8945)
          // withSmartBanner={false}
          onCloseSmartBanner={onCloseSmartBanner}
          smartBannerGaEvent={smartBannerGaEvent}
          logoLink='/news/cat/headline'
        />
      </div>
      {isAccountPanelOpen && (
        <DynamicAccountPanel
          isOpen={isAccountPanelOpen}
          isLogin={isLogin}
          login={panelLoginHandler}
          logout={panelLogoutHandler}
          profile={profile}
          onClosePanel={onShowOrHideAccountPanel(false)}
          cnyesBaseUrl={cnyesBaseUrl}
          navs={accountMenus}
          isMobile
        />
      )}
      {isCatBoardOpen && <DynamicMobileMenu navs={mobileHeaderConfig.items} onClose={onShowOrHideCatBoard(false)} />}
      {isNewsDetailInMobile && <AnueTopBar hasSmartBanner={isShowSmartBanner} newsId={Number(newsId)} />}
    </MobileHeaderContainer>
  );
};

export default NavigatorMobile;
