'use client';

import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { styled } from '@linaria/react';
import Link from 'next/link';
import { RefObject, useEffect, useRef, useState } from 'react';
import AnueSearch from '../AnueSearch/AnueSearch2';
import SmartBanner, { BANNER_HEIGHT } from '../SmartBanner/SmartBanner';
import LinkFactory from '@fe-common-utils/libs/link';

const MOBILE_HEADER_HEIGHT = 44;

type MobileHeaderProps = {
  BellComponent: React.ReactNode;
  AccountComponent: React.ReactNode;
  LinksBarComponent: React.ReactNode;
  shouldDisplayBackIcon: boolean;
  env: string;
  searchKeyword: string;
  withSmartBanner: boolean;
  // TODO: 沒有這個函式的對應處理，但外部元件有傳入處理函式
  onCloseSmartBanner: () => void;
  onShowCatBoard: () => void;
  smartBannerGaEvent?: unknown;
  logoLink: string;
};

const Container = styled.section`
  height: ${MOBILE_HEADER_HEIGHT}px;
  width: 100%;
  border-bottom: solid 1px #c0c0c0;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 8px;

  &:after {
    content: '';
    position: absolute;
    height: 2px;
    right: 0;
    left: 0;
    top: 0;
    background: linear-gradient(to right, #54b5c6 60%, #e03f19 60%, #e03f19 90%, #ffb43e 90%);
    z-index: 2;
  }

  @media screen and (min-width: ${SCREEN_SIZE.DESKTOP}px) {
    display: none;
  }
`;

const Icon = styled.img`
  cursor: pointer;
`;

const BackIcon = styled(Icon)`
  margin-right: 8px;
`;

const Logo = styled(Link)`
  margin-left: 14px;

  img {
    background-size: cover;
  }
`;

const LeftContainer = styled.div``;
const RightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 8px;
`;

const MiddleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-right: 4px;
`;

const SearchIcon = styled.img`
  cursor: pointer;
`;

const SearchContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 1;
`;

export const MobileHeader = ({
  BellComponent,
  AccountComponent,
  LinksBarComponent,
  onShowCatBoard,
  logoLink,
  withSmartBanner = false,
  onCloseSmartBanner
}: MobileHeaderProps) => {
  const [isOpenSearchBar, setIsOpenSearchBar] = useState<boolean>(false);
  const containerRef: RefObject<HTMLDivElement> = useRef(null);

  const handleCloseSearch = () => {
    setIsOpenSearchBar(v => !v);
  };

  /**調整 container 位置 */
  useEffect(() => {
    const container = containerRef.current;
    const contentMainNews = document.getElementById('content-main-news');
    const contentPopTopic = document.getElementById('content-pop-topic');
    const fixedPopTopicFilter = document.getElementsByClassName('fixed-pop-topic-filter');
    const popTopicGoBackButton = document.getElementById('pop-topic-go-back-button');

    if (fixedPopTopicFilter.length > 0) {
      for (let element of fixedPopTopicFilter) {
        if (withSmartBanner) {
          (element as HTMLElement).style.top = '98px';
        } else {
          (element as HTMLElement).style.top = '44px';
        }
      }
    }

    if (contentMainNews) {
      if (withSmartBanner) {
        contentMainNews.style.paddingTop = `${BANNER_HEIGHT}px`;
      } else {
        contentMainNews.style.paddingTop = '0px';
      }
    }

    if (contentPopTopic) {
      if (withSmartBanner) {
        contentPopTopic.style.paddingTop = '72px';
      } else {
        contentPopTopic.style.paddingTop = '24px';
      }
    }

    if (container) {
      if (withSmartBanner) {
        container.style.top = `${BANNER_HEIGHT}px`;
      } else {
        container.style.top = '0';
      }
    }

    if (popTopicGoBackButton) {
      if (withSmartBanner) {
        popTopicGoBackButton.style.top = '58px';
      } else {
        popTopicGoBackButton.style.top = '5px';
      }
    }
  }, [withSmartBanner]);

  return (
    <>
      {withSmartBanner && <SmartBanner onClose={onCloseSmartBanner} />}
      <Container ref={containerRef}>
        {isOpenSearchBar ? (
          <BackIcon
            width={18}
            height={18}
            alt='go-back-icon'
            src='/assets/icons/arrows/header-left.svg'
            onClick={handleCloseSearch}
          />
        ) : (
          <Icon alt='menu-icon' src='/assets/icons/menu/menu.svg' height={18} width={18} onClick={onShowCatBoard} />
        )}
        <MiddleContainer>
          {isOpenSearchBar && (
            <SearchContainer>
              <AnueSearch hideSearch />
            </SearchContainer>
          )}

          <LeftContainer>
            <Logo href={logoLink}>
              <img alt='anue logo' src='/assets/icons/logo/anue-logo-colorful.svg' height={30} width={70} />
            </Logo>
          </LeftContainer>
          <RightContainer>
            {LinksBarComponent && LinksBarComponent}
            {BellComponent && BellComponent}
            {AccountComponent && AccountComponent}
          </RightContainer>
        </MiddleContainer>
        <SearchIcon
          alt='search icon'
          src='/assets/icons/search/search.svg'
          width={18}
          height={18}
          onClick={handleCloseSearch}
        />
      </Container>
    </>
  );
};

export default MobileHeader;
