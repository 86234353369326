export function getBaseUrlEnv(): string {
  const environment = String(process.env.NEXT_PUBLIC_ENVIRONMENT);
  let result = 'int';

  switch (environment) {
    case 'int':
      result = 'int';
      break;
    case 'beta':
      result = 'beta';
      break;
    case 'gamma':
    case 'stage':
    case 'prod':
    default:
      result = environment;
      break;
  }

  return result;
}
