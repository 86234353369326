import LinkFactory, { generateStockLinkBySymbol } from '@fe-common-utils/libs/link';
import { postTime } from '@fe-common-utils/libs/time';
import { styled } from '@linaria/react';
import { useState } from 'react';
import Cookie from 'universal-cookie';
import type { AnueSearchResultProps, HotQuoteType, QuoteFundsItem, SearchNews } from './AnueSearch.d';
import { generateTradeSearchLink, getCategory, getExchange } from './helper';

const cookie = new Cookie();

const Container = styled.ul`
  top: 43px;
  left: 0;
  right: 0;
  bottom: auto;
  border-radius: 6px;
  border: 1px solid rgba(0, 65, 143, 0.1);
  box-shadow: 0 0 6px 0 rgba(0, 65, 143, 0.2);
  position: absolute;
`;

const CategoryItem = styled.li`
  background-color: white;
`;

const ItemTitle = styled.p`
  height: 20px;
  padding: 2px 8px;
  background-color: #edf1f8;
  border: none;
  font-size: 14px;
  color: #262626;
`;

const ListContainer = styled.ul`
  padding: 12px 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px 8px;
  background-color: white;
`;

const Tag = styled.li`
  a {
    font-size: 15px;
    font-weight: 300;
    line-height: 1;
    color: #0073ff;
    text-decoration: underline;
  }
`;

const HotQuote = styled.li`
  border-bottom: 1px solid #e0e0e0;
  padding: 12px 8px 12px 27px;
  position: relative;
  letter-spacing: 0.5px;

  a {
    color: #1e1e1e;

    :hover {
      font-weight: 500;
    }

    &::before {
      content: '';
      background: url('/assets/icons/hot/fire.svg');
      position: absolute;
      width: 11px;
      height: 14px;
      left: 8px;
      top: 16px;
    }
  }
`;

const MarkContainer = styled(ListContainer)`
  mark {
    color: #e03f19;
    background-color: inherit;
  }
`;

const QuoteFunds = styled.li`
  background: white;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  padding-bottom: 12px;

  :last-child {
    border-bottom-width: 0;
    padding-bottom: 0;
  }

  a {
    color: #1e1e1e;
    cursor: pointer;
  }

  & .title {
    justify-content: flex-start;
    align-items: center;
    min-height: 100%;
    margin-bottom: 6px;
    font-size: 15px;

    small {
      margin-left: 8px;
      font-size: 10px;
      transform: scale(0.85) translate(-10%);

      &:before {
        content: '/';
        font-size: 10px;
        font-weight: normal;
        margin-right: 4px;
      }
    }
  }

  & .summary {
    color: #909090;
    font-size: 13px;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`;

const NewsListItem = styled.li`
  background: white;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  padding-bottom: 12px;

  :last-child {
    border-bottom-width: 0;
    padding-bottom: 0;
  }

  a {
    font-size: 15px;
    color: #1e1e1e;
    cursor: pointer;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    margin-bottom: 6px;
  }

  time {
    font-size: 12px;
    color: #909090;

    &::before {
      content: '';
      background-image: url('/assets/icons/time/clock.svg');
      width: 12px;
      height: 12px;
      margin-right: 6px;
      display: inline-block;
      vertical-align: middle;
    }
  }
`;

const ReadMoreItemTitle = styled(ItemTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;

  a {
    color: #515c69;
  }

  button {
    color: #909090;
    font-size: 12px;
    cursor: pointer;
  }
`;

const RecentListItme = styled.li`
  padding: 12px 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;

  a {
    color: #1e1e1e;
  }

  img {
    cursor: pointer;
  }
`;

const RecentSearch = () => {
  const [recents, setRecents] = useState(cookie.get('recents') || []);

  const handleRemoveHistory = (history: string) => (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    const newRecents = recents.filter((r: string) => r !== history);
    cookie.set('recents', newRecents);
    setRecents((prev: string[]) => prev.filter((r: string) => r !== history));
  };

  const handleClearHistory = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    cookie.remove('recents');
    setRecents([]);
  };

  if (recents?.length === 0) return null;

  return (
    <CategoryItem>
      <ReadMoreItemTitle>
        <span>最近搜尋</span>
        <button onClick={handleClearHistory}>全部刪除</button>
      </ReadMoreItemTitle>
      <ul>
        {recents.map((histroy: string) => {
          const url = `${LinkFactory.wwwChannelLink}/search/all?keyword=${histroy}`;

          return (
            <RecentListItme key={histroy}>
              <a href={url}>{histroy}</a>
              <img
                src='/assets/icons/close/close.svg'
                width={12}
                height={12}
                onClick={handleRemoveHistory(histroy)}
                alt='close icon'
              />
            </RecentListItme>
          );
        })}
      </ul>
    </CategoryItem>
  );
};

export const DefaultSearchResult = ({ tags, hotQuote }: { tags: { tag: string }[]; hotQuote: HotQuoteType[] }) => {
  return (
    <>
      <CategoryItem>
        <ItemTitle>熱搜</ItemTitle>
        <ListContainer>
          {tags.map(({ tag }: { tag: string }) => (
            <Tag key={tag}>
              <a href={`${LinkFactory.wwwChannelLink}/search/all?keyword=${tag}`}>{tag}</a>
            </Tag>
          ))}
        </ListContainer>
      </CategoryItem>
      <CategoryItem>
        <ItemTitle>熱門行情</ItemTitle>
        <ul>
          {hotQuote.map(({ displayName, symbol }) => (
            <HotQuote key={displayName}>
              <a href={generateStockLinkBySymbol(symbol)}>{displayName}</a>
            </HotQuote>
          ))}
        </ul>
      </CategoryItem>
      <RecentSearch />
    </>
  );
};

const SearchResult = ({
  news,
  quoteFunds,
  searchWords
}: {
  news: SearchNews[];
  quoteFunds: QuoteFundsItem[];
  searchWords: string;
}) => {
  const renderQuoteResult = () => {
    if (quoteFunds?.length === 0) return null;

    return (
      <CategoryItem>
        <ReadMoreItemTitle>
          <span>行情</span>
          <a href={`${LinkFactory.wwwChannelLink}/search/quote?keyword=${searchWords}`}>看更多</a>
        </ReadMoreItemTitle>
        {
          <MarkContainer>
            {quoteFunds?.map((trade: any, index: number) => {
              const category = getCategory(trade);
              const exchange = getExchange(trade);
              const info = [category, exchange].filter(v => !!v).join('-');
              const name = trade.code || trade.displayNameLocal;
              const href = generateTradeSearchLink(trade, trade.objectType);

              return (
                <QuoteFunds key={`qutoe-funds-result-${index}`}>
                  <a href={href}>
                    <p className='title'>
                      <span dangerouslySetInnerHTML={{ __html: name }} />
                      <small>{info}</small>
                    </p>
                    <p className='summary'>
                      <span dangerouslySetInnerHTML={{ __html: trade.chName }} />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: trade.enName || trade.displayName
                        }}
                      />
                    </p>
                  </a>
                </QuoteFunds>
              );
            })}
          </MarkContainer>
        }
      </CategoryItem>
    );
  };

  const renderNewsResult = () => {
    if (news?.length === 0) return null;

    return (
      <CategoryItem>
        <ReadMoreItemTitle>
          <span>新聞</span>
          <a href={`${LinkFactory.wwwChannelLink}/search/news?keyword=${searchWords}`}>看更多</a>
        </ReadMoreItemTitle>
        <MarkContainer>
          {news?.map(({ newsId, title, publishAt }) => {
            const url = `${LinkFactory.newsChannelLink}/news/id/${newsId}`;

            return (
              <NewsListItem key={newsId}>
                <a href={url} dangerouslySetInnerHTML={{ __html: title }} />
                <time>{postTime(publishAt)}</time>
              </NewsListItem>
            );
          })}
        </MarkContainer>
      </CategoryItem>
    );
  };

  return (
    <>
      {renderQuoteResult()}
      {renderNewsResult()}
    </>
  );
};

const AnueSearchResult = ({ tags, hotQuote, news, quoteFunds, searchWords }: AnueSearchResultProps) => {
  const isEmpty = news?.length === 0 && quoteFunds?.length === 0;

  return (
    <Container>
      {isEmpty ? (
        <DefaultSearchResult tags={tags} hotQuote={hotQuote} />
      ) : (
        <SearchResult news={news} quoteFunds={quoteFunds} searchWords={searchWords} />
      )}
    </Container>
  );
};

export default AnueSearchResult;
