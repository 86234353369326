import { styled } from '@linaria/react';

// TOOD: navs types
type MobileMenuProps = {
  navs: any;
  onClose: () => void;
};

const Container = styled.aside`
  position: relative;
  background: white;
  z-index: 10;
  overflow: scroll;
  height: 100vh;
`;

const CloseIcon = styled.img`
  cursor: pointer;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  border-bottom: 1px solid #c0c0c0;
  margin-bottom: 10px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 11;
  padding: 0 10px;

  &:after {
    content: '';
    position: absolute;
    height: 2px;
    right: 0;
    left: 0;
    top: 0;
    background: linear-gradient(
      to right,
      #54b5c6 60%,
      #e03f19 60%,
      #e03f19 90%,
      #ffb43e 90%
    );
    z-index: 2;
  }
`;

const Content = styled.ul``;

const MenuTitle = styled.h3`
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  margin-left: 30px;
  padding-bottom: 8px;
  color: #54b5c6;
  border-bottom: 1px solid #d8d8d8;
  position: relative;

  ::after {
    content: '';
    background: url('/assets/icons/arrows/menu-blue-right.svg');
    display: inline-block;
    margin-left: 16px;
    vertical-align: -5%;
    width: 16px;
    height: 16px;
  }
`;

const MenuItemContent = styled.div`
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
`;

const MenuItem = styled.a<{ highlight: boolean }>`
  padding-left: 30px;
  color: ${({ highlight }) => (highlight ? '#e03f19' : '#1e1e1e')};
  display: block;
  height: 36px;
  width: 50%;
  font-weight: normal;
`;

export const MobileMenu = ({ onClose, navs }: MobileMenuProps) => {
  return (
    <Container>
      <Header>
        <img
          alt="anue-logo"
          src="/assets/icons/logo/anue-colorful.svg"
          width={70}
          height={22}
        />
        <CloseIcon
          src="/assets/icons/close/close.svg"
          alt="close panel"
          width={16}
          height={16}
          onClick={onClose}
        />
      </Header>
      <Content>
        {navs.map(
          ({
            title,
            items = [],
          }: {
            title: string;
            items: {
              name: string;
              title: string;
              url: string;
              highlight: boolean;
            }[];
          }) => {
            return (
              <li key={title}>
                <MenuTitle>{title}</MenuTitle>
                <MenuItemContent>
                  {items.map(({ title, url, highlight }) => (
                    <MenuItem title={title} href={url} highlight={highlight}>
                      {title}
                    </MenuItem>
                  ))}
                </MenuItemContent>
              </li>
            );
          }
        )}
      </Content>
    </Container>
  );
};

export default MobileMenu;
