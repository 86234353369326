'use client';

import dynamic from 'next/dynamic';
import { Suspense } from 'react';

const DynamicScrollToTop = dynamic(() => import('@fe-common-ui/ScrollToTop'), {
  ssr: true
});

const DynamicSnackbar = dynamic(() => import('@fe-common-ui/Snackbar'), {
  ssr: true
});

const DynamicAnueLiveStream = dynamic(() => import('@fe-news/components/molecules/AnueLiveStream/AnueLiveStream'), {
  ssr: true
});

/**
 * 浮水印浮動廣告
 */
const DynamicFloatingButton = dynamic(() => import('@fe-news/components/atoms/FloatingButton/FloatingButton'), {
  ssr: false
});

type Props = {
  floatingAd: AsideCnyesAd.Response;
};

export default function DynamicLoadGlobalShareComponents({ floatingAd }: Props) {
  const { image, content, url } = floatingAd;

  return (
    <Suspense>
      {/* Email 訂閱成功通知彈跳視窗 */}
      <DynamicSnackbar />

      {/* 直播小視窗 */}
      <DynamicAnueLiveStream />

      {/* 移到最上層 */}
      <DynamicScrollToTop
        hasFooter size='m'
        showOnMobile
        customMobileOffset={50}
        stickyComponent={
          <DynamicFloatingButton
            href={url}
            description={content}
            image={image?.xxl?.src || ''}
            width={image?.xxl?.width || 100}
            height={image?.xxl?.height || 136}
          />
        }
      />
    </Suspense>
  );
};
