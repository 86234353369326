import { GETv1Portfolios } from '@fe-news/api/member';
import { useQuery } from '@tanstack/react-query';

const successStatusCode = 200;
const defaultValue = { tabs: [], symbols: [] };

type ProductType = { product_id: string; product_type: string };

type MenberDataType = {
  data: ProductType[];
  name: string;
};

const isEmptyObject = (Object_: { [x: string]: unknown }) => Object.keys(Object_).length === 0;

const fetchData = async () => {
  try {
    const response = await GETv1Portfolios({});
    if (response.status_code === successStatusCode) {
      return response?.items ?? [];
    }

    return [];
  } catch (error) {
    console.log('fetch menber error', error);
    return [];
  }
};

const formateData = (data: { [x: string]: MenberDataType }) => {
  if (isEmptyObject(data)) {
    return defaultValue;
  }
  const tabs: string[] = [];
  const symbols: string[][] = [];
  Object.values(data).forEach(group => {
    const symbolCodes = group.data
      .map((info: ProductType) => (info.product_type === 'FUND' ? '' : info.product_id))
      .filter(Boolean);
    tabs.push(group.name);
    symbols.push(symbolCodes);
  });

  return { tabs, symbols };
};

const useFollowStocks = (subTabType: number | null, isLogin: boolean) => {
  const { data } = useQuery({
    queryKey: ['member-api', isLogin],
    queryFn: fetchData,
    select: formateData,
    initialData: {},
    enabled: isLogin
  });

  return {
    tabs: data?.tabs ?? [],
    symbols: subTabType === null ? data.symbols.flat() : data?.symbols?.[subTabType]
  };
};

export default useFollowStocks;
