'use client';
import LinkFactory from '@fe-common-utils/libs/link';
import { styled } from '@linaria/react';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import { useEffect, useRef, useState } from 'react';
import Cookie from 'universal-cookie';
import { GETHotQuote, GETRecommendTags, GETSearchKeyword } from '../apis/search';
import AnueSearchResult from './AnueSearchResult';

// @TODO: 配合上線，為不讓手機破版，應急用

const cookie = new Cookie();

export type AnueSearchProps = {
  placeholder?: string;
  hideSearch?: boolean;
};

type AnueSearchInputProps = {
  placeholder?: string;
  isFocus: boolean;
  onFocus?: (state: boolean) => void;
  onChange?: React.Dispatch<React.SetStateAction<string>>;
  isShowClear?: boolean;
  searchWords?: string;
  hideSearch?: boolean;
};

const Container = styled.div`
  position: relative;
`;

const InputContainer = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  align-items: center;
`;

const AnueInput = styled.input`
  border-radius: 2px;
  font-size: 13px;
  transition:
    flex 0.3s cubic-bezier(0.42, 0, 0.58, 1),
    width 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  height: 34px;
  padding: 8px 32px 8px 7px;
  border: 1px solid #e2e8f1;
  background-color: #eeeeee;
  width: 100%;

  &.focus {
    border-color: #3ec1ce;
    background-color: white;
    box-shadow: 0 0 2px 2px #c1f6ff;
  }
`;

const InputButtons = styled.div`
  position: absolute;
  right: 10px;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Icon = styled.img`
  cursor: pointer;
`;

const fetchTagsData = async () => {
  try {
    const response = await GETRecommendTags();

    return response?.items;
  } catch (error) {
    console.error('[Header熱搜標籤API錯誤]:', error);

    return [];
  }
};

const fetchHotQuote = async () => {
  try {
    const response = await GETHotQuote();

    return response?.data;
  } catch (error) {
    console.error('[Header熱門行情API錯誤]:', error);

    return [];
  }
};

const fetchSearchKeyword = async (word: string) => {
  try {
    const response = await GETSearchKeyword(word);

    return response.data ?? {};
  } catch (error) {
    console.error('[Header全站搜尋API錯誤]:', error);

    return {
      news: [],
      quoteFunds: []
    };
  }
};

const AnueSearchInput = ({
  placeholder,
  isFocus,
  onFocus,
  onChange,
  isShowClear,
  hideSearch
}: AnueSearchInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isChinese, setIsChinese] = useState(false);

  const handleFocus = (state: boolean) => () => {
    onFocus?.(state);
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value);
  };

  const handleClearInput = () => {
    onChange?.('');

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleSearch = () => {
    if (inputRef.current) {
      const keyword = inputRef.current.value;

      if (keyword === '') return;

      const recents = new Set(cookie.get('recents'));

      recents.add(keyword);
      cookie.set('recents', [...recents]);

      window.location.href = `${LinkFactory.wwwChannelLink}/search/all?keyword=${keyword}`;

      handleClearInput();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      // 搜尋中文時避免按Enter後馬上送出(中文會有Enter選字問題)
      if (isChinese) {
        setIsChinese(false);
      } else {
        handleSearch();
      }
    }
  };

  return (
    <InputContainer>
      <AnueInput
        placeholder={placeholder}
        className={cx({ focus: isFocus })}
        onFocus={handleFocus(true)}
        onChange={debounce(handleChangeInput, 500)}
        ref={inputRef}
        onKeyDown={handleKeyDown}
        onCompositionStart={() => setIsChinese(true)}
      />

      <InputButtons>
        {isShowClear && (
          <Icon
            alt='clear input icon'
            src='/assets/icons/close/search-close.svg'
            width={15}
            height={15}
            onClick={handleClearInput}
          />
        )}

        {!hideSearch && (
          <Icon alt='search-icon' src='/assets/icons/search/search.svg' width={18} height={18} onClick={handleSearch} />
        )}
      </InputButtons>
    </InputContainer>
  );
};

const AnueSearch = ({ placeholder = '搜尋新聞、代碼或名稱', hideSearch = false }: AnueSearchProps) => {
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [data, setData] = useState({ tags: [], hotQuote: [] });
  const ref = useRef<HTMLDivElement>(null);
  const [searchWords, setSearchWords] = useState<string>('');
  const [searchResult, setSearchResult] = useState({
    news: [],
    quoteFunds: []
  });

  const handleDOMClick = (e: MouseEvent) => {
    // 點擊AnueSearch以外的地方關閉Focus
    if (!ref?.current?.contains(e.target as Node)) {
      setIsFocus(false);
    }
  };

  // 監聽點擊搜尋元件以外的地方需要關閉搜尋結果彈出視窗
  useEffect(() => {
    window.addEventListener('click', handleDOMClick);

    return () => {
      window.removeEventListener('click', handleDOMClick);
    };
  }, []);

  useEffect(() => {
    // 展開搜尋框才打API,且開關搜尋框不重打API
    if (isFocus && data.tags.length === 0) {
      Promise.all([fetchTagsData(), fetchHotQuote()]).then(([tags, hotQuote]) => {
        setData({ tags, hotQuote });
      });
    }
  }, [isFocus, data]);

  useEffect(() => {
    if (searchWords !== '') {
      fetchSearchKeyword(searchWords).then(({ news, quoteFunds }) => {
        setSearchResult({ news, quoteFunds });
      });
    } else {
      setSearchResult({
        news: [],
        quoteFunds: []
      });
    }
  }, [searchWords]);

  return (
    <Container ref={ref}>
      <AnueSearchInput
        placeholder={placeholder}
        onFocus={setIsFocus}
        isFocus={isFocus}
        onChange={setSearchWords}
        isShowClear={searchWords !== ''}
        searchWords={searchWords}
        hideSearch={hideSearch}
      />
      {isFocus && <AnueSearchResult {...data} {...searchResult} searchWords={searchWords} />}
    </Container>
  );
};

export default AnueSearch;
